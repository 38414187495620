import React from "react";
import "./Mentions-legales.scss";

export function MentionsLegales() {
  return (
    <div className="Contact-page">
      <span className="Title">
        Mentions <strong>Legales</strong>
      </span>

      <h4>Informations générales</h4>

      <p>
        {" "}
        Ce site est la propriété de Immo TEP, société par actions simplifiée
        (SAS) au capital social de 2 000 euros, dont le siège social est situé
        au 22 Chemin Cresson, 97460 St Paul, France, immatriculée au Registre du
        Commerce et des Sociétés sous le numéro 980 751 598. Immo TEP opère sous
        le numéro de carte professionnelle CPI 9741 2023 000 000 028 délivrée
        par la CCI de la Réunion, garantissant ainsi son droit d’exercer des
        activités immobilières conformément à la réglementation française.
      </p>

      <h4>Garantie financière et assurance</h4>
      <p>
        Notre agence dispose d’une garantie financière dont les détails (montant
        et organisme garant) seront fournis sur demande. Nous sommes également
        assurés pour notre responsabilité civile professionnelle par Allianz Ark
        Assur. Pour toute demande relative à notre garantie financière ou notre
        assurance, veuillez nous contacter directement.
      </p>

      <h4>Directeur de la publication</h4>
      <p>Le directeur de la publication du site est Angèle ABYLON.</p>

      <h4>Informations techniques</h4>

      <p>
        Le site est créé par Maxime ABYLON. Il est hébergé par Digital Ocean,
        une société reconnue pour ses solutions d'hébergement cloud. Pour toute
        question technique ou concernant l'hébergement, veuillez vous référer
        directement à Digital Ocean.
      </p>

      <h4>Politique de confidentialité et gestion des données</h4>

      <p>
        Notre site collecte des données personnelles telles que le nom et
        l’adresse e-mail dans le cadre de son fonctionnement. Ces données sont
        utilisées exclusivement dans le cadre de nos activités immobilières,
        pour la gestion des demandes de nos clients et prospects. Nous nous
        engageons à ne pas partager ces informations avec des tiers sans
        consentement explicite.
      </p>

      <p>
        Les données collectées sont conservées pour une durée conforme aux lois
        en vigueur et aux besoins de traitement. Les utilisateurs disposent de
        droits d’accès, de rectification et de suppression de leurs données
        personnelles. Pour exercer ces droits, veuillez nous contacter
        directement.
      </p>

      <h4>Utilisation des cookies</h4>

      <p>
        Notre site ne utilise pas de cookies. Cela signifie que nous ne stockons
        aucune information sur votre ordinateur ou appareil mobile qui pourrait
        être utilisée pour vous suivre ou pour mémoriser vos préférences de
        navigation entre les sessions.
      </p>

      <p>
        Nous vous remercions de votre confiance et vous souhaitons une agréable
        visite sur Immotep.re.
      </p>
    </div>
  );
}
