import React, { useEffect, useState } from "react";
import "./Admin.scss";
import { HouseProps, HouseService } from "../../services/HouseService";
import { useNavigate } from 'react-router-dom';

interface AdminProps {
  houseService: HouseService;
}

export function Admin({ houseService }: AdminProps) {
  const [houses, setHouses] = useState<HouseProps[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchHouse() {
      const data = await houseService.getHouses()

      if (data) {
        setHouses(data);
      }
    }

    fetchHouse();
  }, [])

  function onSwitchToCreatePage() {
    navigate('/creer');
  }
  
  return (
    <div className="Admin-page">
      <h2>Admin</h2>
      <div className="Toolbar">
        <button onClick={onSwitchToCreatePage}>Creer</button>
      </div>
      <div className="Admin-form">
        {houses.map((house, index) => (<div key={index}>{house.city}</div>))}
      </div>
    </div>
  );
}
