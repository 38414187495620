import { Link } from "react-router-dom";
import logo from "../../assets/flag_reunion.svg";
import "./Footer.scss";

export function Footer() {
  return (
    <footer>
      <div className="footer-wrapper">
        <span>© {new Date(Date.now()).getFullYear()} Immotep</span>
        <img src={logo} alt="flag reunion" />
        <span>
          <Link className="links" to="contact">
            Contact
          </Link>
        </span>

        <span>
          <Link className="links" to="data">
            Données personnelles
          </Link>
        </span>

        <span>
          <Link className="links" to="cookies">
            Cookies
          </Link>
        </span>

        <span>
          <Link className="links" to="mentions-legales">
            Mention légales
          </Link>
        </span>
      </div>
    </footer>
  );
}
