import React from 'react';
import { Helmet } from 'react-helmet';

const HeadComponent = () => (
  <Helmet>
    <link rel="icon" href="%PUBLIC_URL%/favicon.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <title>Immotep.re - Agence immobilière | Ile de la Réunion</title>
    <meta name="description" content="Trouver votre prochain bien à louer / Acheter. Vous souhaitez vendre ? N'hésitez pas à nous contacter" />
    <link rel="icon" href="/favicon.png" type="image/x-icon" />
    <meta name="keywords" content="agence, reunion, immobilier, immobilière, immobiliere, acheter, louer, vendre, maison, appartement, saint, saint-, 974, bien, villa, Maison à vendre, Appartement en location, Propriété, Terrain constructible" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
      rel="stylesheet"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </Helmet>
);

export default HeadComponent;
