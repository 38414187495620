import { CSSProperties } from "react";
import "./Button.scss";
import ClipLoader from "react-spinners/ClipLoader";

interface ButtonProps {
  text: string;
  isLoading?: boolean;
  type?: "button" | "submit";
  onClick: () => void;
}

const override: CSSProperties = {
  display: "block",
  margin: "0",
  borderColor: "white",
};

export function Button({
  text,
  isLoading,
  type = "button",
  onClick,
}: ButtonProps) {
  return (
    <button onClick={onClick} type={type}>
      {isLoading && (
        <ClipLoader
          color="white"
          loading={true}
          cssOverride={override}
          size={16}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {text}
    </button>
  );
}
