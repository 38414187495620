import React, { useEffect, useState } from "react";
import "./AVendre.scss";
import { Spacer } from "../../components/Spacer";
import {
  SearchBar,
  SearchParams,
} from "../../components/Search-bar/Search-bar";
import { HouseProps, HouseService } from "../../services/HouseService";
import { HouseBox } from "../../components/House-box/House-box";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageService } from "../../services/ImageService";

interface AVendreProps {
  houseService: HouseService;
  imageService: ImageService;
}

export function AVendre({ houseService, imageService }: AVendreProps) {
  const [houses, setHouses] = useState<HouseProps[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.state?.search;

  useEffect(() => {
    if (searchParams) {
      houseService.searchHouses(searchParams).then((houses) => {
        if (houses) {
          setHouses(houses);
        }
      });
    } else {
      houseService.getHouses(10).then((houses) => {
        setHouses(houses);

        houses.forEach((house: HouseProps) => {
          imageService.getImages(house.id as string).then((images) => {
            if (images) {
              house.images = images;
              setHouses([...houses]);
            }
          });
        });
      });
    }
  }, []);

  function onSearch(searchParams: SearchParams) {
    houseService.searchHouses(searchParams).then((houses) => {
      if (houses) {
        setHouses(houses);
      }
    });
  }

  function onHouseClick(houseId: string) {
    navigate(`/house/${houseId}`);
  }

  return (
    <div className="A-Vendre">
      <span className="Title">
        Nos logements <strong>à vendre</strong>
      </span>
      <Spacer size="xlarge" />
      <div className="searchbar-wrapper">
        <SearchBar
          onSearch={onSearch}
          houseService={houseService}
          hasShadow={false}
        />
      </div>
      <div className="line"></div>
      <div className="house-list">
        {houses.map((house, index) => (
          <div className="housebox-wrapper">
            <HouseBox
              key={index}
              onClick={onHouseClick}
              params={{
                city: house.city,
                id: house.id as string,
                price: house.price,
                surface: house.surface,
                titre: house.title,
                type: house.type,
                images: house.images,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
