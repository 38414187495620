const sizes = {
  small: 8,
  medium: 16,
  large: 24,
  xlarge: 40,
};


export function Spacer({ size }: { size: keyof typeof sizes }) {
  return  <div style={{ height: sizes[size] }}></div>;
}
