import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo_short.svg";
import { Button } from "../../components/Button/Button";
import "./Header.scss";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Header() {
  let navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  function handleClick(path: string) {
    navigate(`/${path}`);
  }

  function displayMenu(customClass: string = "") {
    return (
      <nav className={`${customClass}`}>
        <ul>
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/a-vendre">Acheter</Link>
          </li>
          {/*<li>
            <Link to="/vendu">Nos ventes</Link>
          </li>*/}
        </ul>
        <Button text="Contact" onClick={() => handleClick("contact")} />
      </nav>
    );
  }

  return (
    <header>
      <div className="header-wrapper">
        <div className="logo" onClick={() => handleClick("")}>
          <img className="immotep-logo-header" src={logo} alt="logo" />
          <div className="text">
            <span className="slogan">
              Agence immobilière - Ile de la Réunion
            </span>
            <span className="logo-text">Immotep</span>
          </div>
        </div>
        <div className="menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FontAwesomeIcon icon={faBars} size="2x" className="icon" />
        </div>
        <div className={`sidebar ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
          {displayMenu(isMenuOpen ? "menu-open" : "")}
        </div>
        {displayMenu()}
      </div>
    </header>
  );
}
