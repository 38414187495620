import { formatNumber } from "../../utils/format-number";
import "./House-box.scss";

interface House {
  titre: string;
  city: string;
  price: number;
  surface: number;
  type: string;
  id: string;
  images?: string[];
}

interface HouseBoxProps {
  params: House;
  onClick: (houseId: string) => void;
}

export function HouseBox({ onClick, params }: HouseBoxProps) {
  const hasImage = params.images && params.images.length > 0;
  // @ts-ignore
  const imageUrl = hasImage ? params.images[0] : 'path/to/default/image.jpg'; 

  return (
    <div className="House-box" onClick={() => onClick(params.id)}>
      <div className="House-box-wrapper">
        {hasImage && (
          <div className="House-box-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
        )}
        <div className="House-box-footer">
          <span className="titre">{params.titre}</span>
          <div className="House-box-header">
            <div className="House-box-header-left">
              <span className="House-box-city">{params.city}</span>
              <div className="House-box-custom">
                <span>{params.type}</span>
                <div className="Rounded-separator"></div>
                <span>{params.surface} m²</span>
              </div>
            </div>
            <span className="House-box-header-right">{formatNumber(params.price)} €</span>
          </div>
        </div>
      </div>
    </div>
  );
}
