import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Pictures-viewer.scss";
import { useState } from "react";

interface PicturesViewerProps {
  pictures: string[];
}

export function PicturesViewer({ pictures }: PicturesViewerProps) {
  const [currentPicture, setCurrentPicture] = useState(0);

  const goLeft = () => {
    setCurrentPicture((prev) => (prev === 0 ? pictures.length - 1 : prev - 1));
  };

  const goRight = () => {
    setCurrentPicture((prev) => (prev === pictures.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="Picture-viewer-wrapper">
      <div className="Picture-viewer">
        <FontAwesomeIcon
          className="Arrow Left"
          onClick={goLeft}
          icon={faChevronLeft}
        />
        <FontAwesomeIcon
          className="Arrow Right"
          onClick={goRight}
          icon={faChevronRight}
        />
        <div className="Preview">
          <img src={pictures[currentPicture]} alt="No picture" />
        </div>
        <div className="List">
          {pictures.map((picture, index) => (
            <div
              className={`Vignette ${currentPicture !== index ? "Uncovered" : ""}`}
              key={index}
              style={{
                backgroundImage: `url(${picture})`,
              }}
              onClick={() => setCurrentPicture(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
