import React, { useState } from "react";
import "./Contact.scss";
import { EmailService } from "../../services/EmailService";
import { Loader } from "rsuite";
import { Button } from "../../components/Button/Button";

interface ContactProps {
  emailService: EmailService;
}

export function Contact({ emailService }: ContactProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Access form data
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const firstName = formData.get("prenom") as string;
    const lastName = formData.get("nom") as string;
    const message = formData.get("message") as string;

    const emailContent = { email, firstName, lastName, message };
    console.log('email', emailContent);

    setIsMessageSent(false);
    setIsLoading(true);
    setError(null);

    try {
      await emailService.send(emailContent);
      setIsMessageSent(true);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Contact-page">
      <h2>Contact</h2>
      <p>
        Pour toute information, n’hésitez pas à nous contacter en complétant le
        formulaire, nous vous recontacterons au plus vite.
      </p>
      {isLoading && <Loader size="md" />}
      {isMessageSent ? (
        <div>
          Merci pour votre message, nous traitons votre demande au plus vite.
        </div>
      ) : (
        <div className="Contact-form">
          <form onSubmit={onSubmitForm}>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                disabled={isLoading}
                required
              />
            </div>

            <div className="twice">
              <div className="field">
                <label htmlFor="prenom">Prénom</label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  disabled={isLoading}
                  required
                />
              </div>
              <div className="field">
                <label htmlFor="nom">Nom</label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  disabled={isLoading}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                disabled={isLoading}
                required
              ></textarea>
            </div>
            <div className="field submit">
              <Button
                isLoading={isLoading}
                type="submit"
                text="Envoyer"
                onClick={() => {}}
              />
              {error && (
                <div className="Error-box">
                  <b>Error: </b>
                  {error}
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
