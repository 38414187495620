import { AxiosError } from "axios";
import axiosInstance from "../utils/fetch";

export class ImageService {
  private static get imagesEndpoint() {
    return "/api/images";
  }

  private handleError(error: unknown): never {
    const axiosError = error as AxiosError;
    console.error(
      "API error:",
      axiosError.response?.data || axiosError.message
    );
    throw axiosError;
  }

  public async getImages(houseId: string) {
    try {
      const { data } = await axiosInstance.get(
        `${ImageService.imagesEndpoint}?houseId=${houseId}`
      );

      const loadedPictures = data.images.map(
        (image: { id: number; image: { type: string; data: Buffer } }) => {
          const base64String = btoa(
            new Uint8Array(image.image.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          return `data:image/jpeg;base64,${base64String}`;
        }
      );

      return loadedPictures;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async uploadImage(
    houseId: string,
    file: File
  ): Promise<string | null> {
    const formData = new FormData();
    formData.append("image", file); // Match this field name with multer config in backend

    try {
      const { data } = await axiosInstance.post(
        `${ImageService.imagesEndpoint}?houseId=${houseId}`, // Correct endpoint
        formData
      );
      return data.imageUrl;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async deleteImage(houseId: string, imageId: string) {
    try {
      await axiosInstance.delete(
        `${ImageService.imagesEndpoint}/images?houseId=${houseId}`
      );
      return "Image deleted successfully";
    } catch (error) {
      return this.handleError(error);
    }
  }
}
