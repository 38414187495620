import axios from "axios";

const API_BASE_URL_DEV = process.env.REACT_APP_SERVER_API_BASE_URL_DEV;
const API_BASE_URL_PROD = process.env.REACT_APP_SERVER_API_BASE_URL_PROD;
const TOKEN = process.env.REACT_APP_SERVER_TOKEN;

// Create an Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: (process.env.NODE_ENV === "production"
    ? API_BASE_URL_PROD
    : API_BASE_URL_DEV),
});

export default axiosInstance;
