import React, { useEffect, useState } from "react";
import "./Home.scss";
import {
  SearchBar,
  SearchParams,
} from "../../components/Search-bar/Search-bar";
import { Carroussel } from "../../components/Carroussel/Carroussel";
import { HouseProps, HouseService } from "../../services/HouseService";
import House from "../../assets/house.svg";
import Call from "../../assets/call.svg";
import Reunion from "../../assets/reunion.svg";
import { Spacer } from "../../components/Spacer";
import { useNavigate } from "react-router-dom";
import { ImageService } from "../../services/ImageService";

interface HomeProps {
  houseService: HouseService;
  imageService: ImageService;
}

export function Home({ houseService, imageService }: HomeProps) {
  const [houses, setHouses] = useState<HouseProps[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchHouses() {
      const houses = await houseService.getHouses(5);
      if (houses) {
        setHouses(houses);

        houses.forEach((house: HouseProps) => {
          imageService.getImages(house.id as string).then((images) => {
            if (images) {
              house.images = images;
              setHouses([...houses]);
            }
          });
        });
      }
    }

    fetchHouses();
  }, []);

  function onIWantToBuy() {
    navigate("a-vendre");
  }

  function onIWantToSell() {
    navigate("contact");
  }

  function onSearch(searchParams: SearchParams) {
    console.log("redirection to search");
    navigate("a-vendre", { state: { search: searchParams } });
  }

  function onHouseClick(houseId: string) {
    navigate(`house/${houseId}`);
  }

  return (
    <div>
      <div className="homepage-cover">
        <div className="searchbar">
          <SearchBar onSearch={onSearch} houseService={houseService} />
        </div>
        <div className="image-filter"></div>
        <div className="title">
          <span>Trouvez votre </span>
          <span>futur logement</span>
        </div>
      </div>

      <div className="home-article services">
        <span className="home-title">
          Accédez à <strong>nos service</strong>
        </span>
        <Spacer size="xlarge" />
        <div className="services-wrapper">
          <div className="service-wrapper" onClick={onIWantToBuy}>
            <img src={House} alt="house" />
            <div className="text">
              <span className="service-title">Vous souhaitez acheter ?</span>
              <span className="service-text">
                Trouvez votre prochain logement
                <br /> en cliquant ici
              </span>
            </div>
          </div>

          <div className="service-wrapper" onClick={onIWantToSell}>
            <img src={Call} alt="house" />
            <div className="text">
              <span className="service-title">Vous souhaitez vendre ?</span>
              <span className="service-text">
                Contactez nous pour vendre votre
                <br /> bien en cliquant ici
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="home-article carroussel">
        <div className="backaground-car"></div>
        <span className="home-title">
          Notre selection <strong>de nouveautés</strong>
        </span>
        <Spacer size="large" />
        <div className="carroussel-wrapper">
          <Carroussel houses={houses} onHouseClick={onHouseClick} />
        </div>
      </div>

      <div className="home-article agence">
        <img src={Reunion} alt="reunion" />
        <div className="agence-text">
          <span className="home-title">
            <strong>Notre agence</strong>
          </span>
          <Spacer size="large" />
          <p>
            Notre agence immobilière familiale place l'accompagnement
            personnalisé et la satisfaction client au cœur de ses priorités.
            Nous nous engageons à suivre chaque projet de près, garantissant une
            expérience sur-mesure du début à la fin. Avec l'innovation et une
            approche attentive, nous visons à simplifier votre parcours
            immobilier, faisant de chaque étape une réussite. Chez nous, votre
            projet devient notre mission.
          </p>
        </div>
      </div>
    </div>
  );
}
