import { AxiosError } from "axios";
import { SearchParams } from "../components/Search-bar/Search-bar";
import axiosInstance from "../utils/fetch";
import { getAuthHeader } from "../utils/get-auth-header";

export interface HouseProps {
  features: string;
  city: string;
  description: string;
  phone: string;
  price: number;
  surface: number;
  title: string;
  type: string;
  id?: string;
  images?: string[];
  ref?: string;
}

export class HouseService {
  private static get houseEndpoint() {
    return "/api/houses";
  }

  private static get citiesEndpoint() {
    return "/api/cities";
  }

  private handleError(error: unknown): null {
    const axiosError = error as AxiosError;
    console.error(
      "API error:",
      axiosError.response?.data || axiosError.message
    );
    return null;
  }

  public async createHouse(house: HouseProps) {
    try {
      const { data } = await axiosInstance.post(
        HouseService.houseEndpoint,
        house,
        { headers: getAuthHeader() }
      );
      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async getHouse(houseId: string) {
    try {
      const { data } = await axiosInstance.get(
        `${HouseService.houseEndpoint}/${houseId}`
      );

      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async getHouses(limit?: number) {
    try {
      const { data } = await axiosInstance.get(HouseService.houseEndpoint, {
        params: { limit },
      });

      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async getCities() {
    try {
      const { data } = await axiosInstance.get(
        `${HouseService.citiesEndpoint}`
      );
      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async searchHouses(params: SearchParams) {
    try {
      const { data } = await axiosInstance.get(
        `${HouseService.houseEndpoint}/search`,
        { params }
      );
      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }
}
