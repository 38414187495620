import React from "react";
import "./Cookie.scss";

export function Cookie() {
  return (
    <div className="Contact-page">
      <span className="Title">
        Politique de Gestion des <strong>Cookies</strong>
      </span>
      <p>
        La présente politique de cookies est intégrée à notre politique de
        protection des données personnelles.
      </p>

      <h4>Aucun Cookie Utilisé</h4>

      <p>
        {" "}
        Lorsque vous naviguez sur notre site internet Immotep.re, nous tenons à
        vous informer que aucune information n'est enregistrée dans votre
        terminal (ordinateur, mobile ou tablette). Immotep.re s'engage dans une
        démarche de respect total de votre vie privée et de transparence avec
        ses visiteurs et clients.
      </p>

      <p>
        Chez Immotep.re, l'absence d'utilisation de cookies signifie que :
        <ul>
          <li>
            Il n'y a pas d'objectif de collecte de données via des cookies.
          </li>
          <li>
            Aucune information n'est collectée automatiquement lors de votre
            visite sur notre site.
          </li>
        </ul>
      </p>

      <h4>Gestion du Consentement</h4>
      <p>
        Du fait de l'absence de cookies sur Immotep.re, la question du
        consentement pour le dépôt et la lecture de cookies ne se pose pas. Vous
        naviguez librement sans qu'aucune donnée ne soit recueillie à votre
        insu.
      </p>

      <h4>Personnalisation des Paramètres</h4>
      <p>
        Puisqu'aucun cookie n'est utilisé sur Immotep.re, il n'est pas
        nécessaire de personnaliser des paramètres relatifs aux cookies.
      </p>

      <h4>Cookies de Réseaux Sociaux</h4>

      <p>
        Notre site ne recourt pas à des cookies liés aux réseaux sociaux pour le
        partage de contenu ou pour toute autre fonctionnalité. Vous pouvez
        partager le contenu de notre site en toute sécurité.{" "}
      </p>

      <h4>Gestion des Cookies dans les Navigateurs</h4>

      <p>
        Bien que notre site n'utilise pas de cookies, vous avez le contrôle
        total sur les cookies enregistrés par d'autres sites dans les paramètres
        de votre navigateur internet. Pour plus d'informations, vous pouvez
        consulter l'aide en ligne de votre navigateur.
      </p>

      <h4>Plus d'Informations</h4>

      <p>
        Pour toute question ou demande d'information supplémentaire concernant
        notre politique de non-utilisation de cookies, nous vous invitons à
        contacter l'agence via les formulaires de contact disponibles sur notre
        site.
      </p>

      <p>
        Nous vous remercions de votre confiance et vous souhaitons une agréable
        visite sur Immotep.re.
      </p>
    </div>
  );
}
