import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';

interface ProtectedRouteProps {
  authService: AuthService;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authService }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await authService.getSession();
        setIsAuthenticated(!!session.data.userData);
      } catch (error) {
        console.error("Session check failed:", error);
        setIsAuthenticated(false); 
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, [authService]);

  if (loading) {
    return <div>Loading...</div>; // Improved loading indication
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
