import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { HouseBox } from "../House-box/House-box";
import "./Carroussel.scss";
import { useEffect, useState } from "react";
import { HouseProps } from "../../services/HouseService";

interface CarrousselProps {
  houses: HouseProps[];
  onHouseClick: (houseId: string) => void;
}

export function Carroussel({ houses, onHouseClick }: CarrousselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calculateVisibleHouses = (windowWidth: number) => {
    if (windowWidth < 812) return 1; // Pour les écrans petits
    if (windowWidth >= 812 && windowWidth < 1024) return 2; // Pour les tablettes
    return 3; // Valeur par défaut pour les écrans plus larges
  };
  const [visibleCount, setVisibleCount] = useState(() =>
    calculateVisibleHouses(window.innerWidth)
  );

  const visibleHouses = houses.slice(currentIndex, currentIndex + visibleCount);
  const isLeftArrowDisabled = currentIndex === 0;
  const isRightArrowDisabled = currentIndex >= houses.length - visibleCount;

  const goLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - visibleCount : 0
    );
  };

  const goRight = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < houses.length - visibleCount
        ? prevIndex + visibleCount
        : prevIndex
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setVisibleCount(calculateVisibleHouses(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Carroussel">
      <div className="left-right" onClick={goLeft}>
        <FontAwesomeIcon
          className="icon"
          icon={faChevronLeft}
          size="2x"
          style={{
            opacity: isLeftArrowDisabled ? 0.5 : 1,
            cursor: isLeftArrowDisabled ? "default" : "pointer",
          }}
        />
      </div>
      <div className="Carroussel-content">
        {visibleHouses.length ? (
          visibleHouses.map((house, index) => (
            <HouseBox
              key={index}
              onClick={onHouseClick}
              params={{
                city: house.city,
                id: house.id as string,
                price: house.price,
                surface: house.surface,
                titre: house.title,
                type: house.type,
                images: house.images,
              }}
            />
          ))
        ) : (
          <div>
            <em>
              Toutes nos maisons ont trouvé leur acquéreur, repassez plus tard !
            </em>
          </div>
        )}
      </div>
      <div className="left-right" onClick={goRight}>
        <FontAwesomeIcon
          className="icon"
          icon={faChevronRight}
          size="2x"
          style={{
            opacity: isRightArrowDisabled ? 0.5 : 1,
            cursor: isRightArrowDisabled ? "default" : "pointer",
          }}
        />
      </div>
    </div>
  );
}
