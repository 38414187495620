import { useState } from "react";
import "./Login.scss";
import { Button } from "../../components/Button/Button";
import { Spacer } from "../../components/Spacer";
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  authService: AuthService;
}

export function Login({ authService }: LoginProps) {
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  function onClick() {
    authService.login(login, password).then((credentials) => {
      if (credentials.error) {
        // @ts-ignore
        setError(credentials.error.response.data);
      } else {
        setError("");
        if (credentials.data.token) {
          navigate("/admin");
        }
      }
    });
  }

  return (
    <div className="Login">
      <div className="Login-wrapper">
        <div className="Login-form">
          <div className="Title">Connexion</div>
          <Spacer size="large" />
          <div className="Input">
            <span>Login</span>
            <input
              type="text"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </div>
          <Spacer size="large" />
          <div className="Input">
            <span>Mot de passe</span>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Spacer size="large" />
          <Button onClick={onClick} text="Se connecter" />
          <Spacer size="large" />
          {error && <div className="Error">{error}</div>}
        </div>
      </div>
    </div>
  );
}
