import React, { useRef } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home } from "./pages/Home/Home";
import { Vendu } from "./pages/Vendu";
import { AVendre } from "./pages/Vendre/AVendre";
import { Contact } from "./pages/Contact/Contact";
import { NotFound } from "./pages/Not-found";
import { MentionsLegales } from "./pages/Mentions-legales/Mentions-legales";
import { Header } from "./pages/Header/Header";
import { Footer } from "./pages/Footer/Footer";
import { Admin } from "./pages/Admin/Admin";
import { HouseService } from "./services/HouseService";
import { Creer } from "./pages/Creer/Creer";
import { Spacer } from "./components/Spacer";
import { Confidentialite } from "./pages/Confidentialite/Confidentialite";
import { Cookie } from "./pages/Cookie/Cookie";
import { HousePage } from "./pages/House-page/House-page";
import { AuthService } from "./services/AuthService";
import { Login } from "./pages/Login/Login";
import { EmailService } from "./services/EmailService";
import { ImageService } from "./services/ImageService";
import ProtectedRoute from "./components/Protected-routes/Protected-routes";
import HeadComponent from "./components/Head";

function App() {
  const emailService = useRef<EmailService>(new EmailService());
  const houseService = useRef<HouseService>(new HouseService());
  const authService = useRef<AuthService>(new AuthService());
  const imageService = useRef<ImageService>(new ImageService());

  return (
    <div className="App">
      <HeadComponent />
      <Router>
        <Header />
        <Spacer size="xlarge" />
        <div id="Main-content">
          <div className="Main-content-wrapper">
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    houseService={houseService.current}
                    imageService={imageService.current}
                  />
                }
              />
              <Route
                path="/a-vendre"
                element={
                  <AVendre
                    houseService={houseService.current}
                    imageService={imageService.current}
                  />
                }
              />
              <Route
                path="/house/:houseId"
                element={
                  <HousePage
                    imageService={imageService.current}
                    houseService={houseService.current}
                  />
                }
              />
              <Route path="/vendu" element={<Vendu />} />
              <Route
                path="/contact"
                element={<Contact emailService={emailService.current} />}
              />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
              <Route path="/data" element={<Confidentialite />} />
              <Route path="/cookies" element={<Cookie />} />

              {/* Administration */}
              <Route
                element={<ProtectedRoute authService={authService.current} />}
              >
                <Route
                  path="/admin"
                  element={<Admin houseService={houseService.current} />}
                />
                <Route
                  path="/creer"
                  element={
                    <Creer
                      houseService={houseService.current}
                      imageService={imageService.current}
                    />
                  }
                />
              </Route>
              <Route
                path="/login"
                element={<Login authService={authService.current} />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
