import axiosInstance from "../utils/fetch";

export class AuthService {
  private static get loginEndpoint() {
    return "/api/users/login";
  }

  private static get sessionEndpoint() {
    return "/api/users/session";
  }

  public async getSession() {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.get(AuthService.sessionEndpoint, {
        headers: {
          Token: `${token}`,
        },
      });
      return { data: response.data, error: null };
    } catch (error) {
      console.error("Failed to validate session", error);
      return { data: null, error };
    }
  }

  public async login(username: string, password: string) {
    try {
      const response = await axiosInstance.post(AuthService.loginEndpoint, {
        username,
        password,
      });

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }

      return { data: response.data, error: null };
    } catch (error) {
      console.error("Login failed", error);
      return { data: null, error };
    }
  }
}
