import axiosInstance from "../utils/fetch";

interface EmailContent {
  email: string;
  firstName: string;
  lastName: string;
  message: string;
}

export class EmailService {
  private static get emailEndpoint() {
    return "/api/send-email";
  }

  public async send(data: EmailContent) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axiosInstance.post(
          `${EmailService.emailEndpoint}`,
          data
        );
  
        resolve(result)
      } catch (error) {
        reject(error);
      }
    })
  }
}
