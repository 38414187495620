import React, { useEffect, useState } from "react";
import "./House-page.scss";
import { PicturesViewer } from "../../components/Pictures-viewer/Pictures-viewer";
import { Button } from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../utils/format-number";
import { Spacer } from "../../components/Spacer";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import { Map } from "../../components/Map/Map";
import { HouseService } from "../../services/HouseService";
import { useParams } from "react-router-dom";
import { ImageService } from "../../services/ImageService";
import _ from "lodash";

interface HousePageProps {
  houseService: HouseService;
  imageService: ImageService;
}

/*const maison = {
  general: {
    "prix de vente": "239 000 €",
    "type de bien": "appartement",
  },
  aspectsFinanciers: {
    "Taxe foncière": "1 091 €",
  },
  surface: {
    surface: "76 m²",
    "surface terrain": "324 m²",
  },
  interieur: {
    "type de chauffage": "Individuel",
    chambres: 2,
    "nombre de pieces": 3,
  },
  geolocalisation: {
    "code postal": 84120,
    pays: "France",
    etage: "0/X",
    exposition: "Sud",
    "nombre d'etages": 0,
  },
  exterieur: {
    jardin: "Oui",
    "neuf / Ancien": "Neuf",
  },
  autres: {
    "acces PMR": "Non",
    "nombre de garage": 1,
    "nombre Places de Parking": 2,
  },
};*/

export function HousePage({ houseService, imageService }: HousePageProps) {
  const [city, setCity] = useState("");
  const [price, setPrice] = useState(0);
  const [surface, setSurface] = useState(0);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState<{ [key: string]: string }>({});
  const [pictures, setPictures] = useState<string[]>([]);
  const [telephone, setTelephone] = useState("Pas de numéro affiché");
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [ref, setRef] = useState<string>("");

  const { houseId } = useParams();
  const onInterestedClick = () => {};

  const convertToHtml = (str: string) => {
    return str.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (houseId) {
      setIsNotFound(false);

      houseService
        .getHouse(houseId)
        .then((house) => {
          if (house) {
            setCity(house.city);
            setPrice(house.price);
            setSurface(house.surface);
            setType(house.type);
            setDescription(house.description);
            setFeatures(house.features);
            setTelephone(house.phone);
            setRef(house.ref);

            imageService.getImages(houseId).then((imageData) => {
              setPictures(imageData);
            });
          } else {
            setIsNotFound(true);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch house:", error);
        });
    }
  }, [houseId]);

  return (
    <div className="A-Vendre">
      {isNotFound ? (
        <div>Cette maison n'existe pas</div>
      ) : (
        <>
          <div className="Header">
            <div className="Header-left">
              <div className="onTitle">
                <span className="Title">{city}</span>
                <span className="Reference">Ref: {ref}</span>
              </div>
              <div className="Line">
                <span className="Title">{_.capitalize(type)}</span>
                <div className="Rounded"></div>
                <span className="Title">{surface}m²</span>
              </div>
              <span className="Title Yellow">{formatNumber(price)} €</span>
            </div>
            <div className="Header-right">
              <Button text="Je suis interessé" onClick={onInterestedClick} />
              <div className="Phone">
                <FontAwesomeIcon className="heart" icon={faPhoneFlip} />
                <span>{telephone}</span>
              </div>
            </div>
          </div>
          <Spacer size="large" />
          <PicturesViewer pictures={pictures} />
          <Spacer size="large" />
          <div className="Description">
            <span className="Title">Description</span>
            <p>{convertToHtml(description)}</p>
          </div>
          {features && Object.entries(features).length && (
            <div className="Caracteristiques">
              <span className="Title">Caracteristiques</span>
              <div className="Columns">
                <div className="Column">
                  {Object.entries(features)
                    .slice(0, Math.ceil(Object.entries(features).length / 2))
                    .map(([key, value]) => (
                      <div key={key} className="Section">
                        <span className="Title">{key.toUpperCase()}</span>
                        <div>
                          {Object.entries(value).map(([key, value]) => (
                            <div key={key}>
                              <span>
                                {capitalizeFirstLetter(key)}:{" "}
                                {capitalizeFirstLetter(value.toString())}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          {/*<div className="Map">
            <span className="Title">Ou se trouve le logement ?</span>
            <Map />
                </div>*/}
        </>
      )}
    </div>
  );
}
