import React from "react";
import "./Confidentialite.scss";

export function Confidentialite() {
  return (
    <div className="Contact-page">
      <span className="Title">
        Politique de <strong>Confidentialité</strong>
      </span>
      <p>
        <h4>Bienvenue sur Immotep</h4>
        Chez Immotep, situé à 97460 Saint Paul, la confidentialité et la
        sécurité en ligne sont au cœur de nos préoccupations. Notre engagement
        est de garantir le respect et la protection de la vie privée de nos
        visiteurs et utilisateurs.
      </p>

      <h4>Collecte et Utilisation des Données</h4>
      <p>
        Collecte des Données : Immotep s'engage à une transparence totale
        concernant les données personnelles. Nous ne collectons aucune donnée
        personnelle sur notre site. Ainsi, aucun traitement, partage ou stockage
        de données personnelles n'est effectué.
      </p>

      <p>
        Objectif de la Collecte : Notre site est conçu pour fournir des
        informations sans nécessiter de données personnelles de la part de nos
        visiteurs, conformément à notre politique de non-collecte.
      </p>

      <p>
        Base Légale : N'étant pas en possession de données personnelles, aucun
        traitement n'est applicable, et donc, aucune base légale n'est requise.
      </p>

      <h4>Transfert et Conservation des Données</h4>

      <p>
        Transfert de Données : Il n'y a aucun transfert de données personnelles,
        y compris en dehors de l'Union européenne, puisque nous ne collectons
        pas de telles données.
      </p>

      <p>
        Conservation des Données : Aucune donnée n'étant collectée, la question
        de la conservation ne se pose pas.
      </p>

      <h4>Droits des Utilisateurs</h4>

      <p>
        Comme Immotep ne recueille aucune donnée personnelle, les droits usuels
        relatifs à l'accès, la rectification ou la suppression ne sont pas
        applicables. Nous sommes dédiés à assurer un espace en ligne sûr et
        transparent.
      </p>

      <h4>Cookies et Technologies Similaires</h4>

      <p>
        Nous informons nos utilisateurs que notre site Immotep n'utilise pas de
        cookies. Votre navigation reste privée et non suivie.
      </p>

      <h4>Sécurité</h4>

      <p>
        La sécurité de notre site est une priorité absolue, même en l'absence de
        collecte de données personnelles. Nous mettons en œuvre des mesures
        techniques pour assurer un environnement sûr pour tous nos visiteurs.
      </p>

      <h4>Contact</h4>

      <p>
        Pour toute question relative à notre politique de confidentialité,
        veuillez noter que nous n'avons pas de Délégué à la Protection des
        Données (DPO), compte tenu de l'absence de collecte de données
        personnelles. Pour toute demande, veuillez nous contacter directement à
        l'adresse située à 97460 Saint Paul.
      </p>
    </div>
  );
}
