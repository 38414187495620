import React, { useState } from "react";
import "./Creer.scss";
import { HouseService } from "../../services/HouseService";
import { ImageService } from "../../services/ImageService";
import { useNavigate } from "react-router-dom";

interface CreerProps {
  houseService: HouseService;
  imageService: ImageService;
}

export function Creer({ houseService, imageService }: CreerProps) {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [type, setType] = useState("appartement");
  const [surface, setSurface] = useState(0); // Supposons que surface et prix sont des nombres
  const [price, setPrice] = useState(0);
  const [pieces, setPieces] = useState(0);
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState("");
  const [ref, setRef] = useState("");
  const [images, setImages] = useState<File[]>([]);

  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setImages(Array.from(event.target.files));
    }
  }

  async function onSubmit(event: React.FormEvent) {
    event.preventDefault(); // Pour éviter le rechargement de la page

    const houseProps = {
      title,
      city,
      type,
      surface,
      price,
      phone,
      description,
      features,
      ref,
    };

    
    try {
      const result = await houseService.createHouse(houseProps);
      if (result) {
        const imageUploadPromises = images.map((image) => imageService.uploadImage(result.id, image));
        await Promise.all(imageUploadPromises);
        
        alert("Maison créée avec succès!");
      }
    } catch (error) {
      console.error("Erreur lors de la création de la maison:", error);
      alert("Erreur lors de la création de la maison.");
    }
  }

  const onReturn = () => {
    navigate('/admin')
  }

  return (
    <div className="Admin-page">
      <h2>Admin</h2>
      <div className="Toolbar">
        <button onClick={onReturn}>Retour</button>
      </div>
      <div className="Admin-form">
        <form>
          <div className="field">
            <label htmlFor="titre">Titre</label>
            <input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              id="titre"
              name="titre"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="ville">Ville</label>
            <input
              onChange={(e) => setCity(e.target.value)}
              value={city}
              type="ville"
              id="ville"
              name="ville"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="ref">Ref</label>
            <input
              onChange={(e) => setRef(e.target.value)}
              value={ref}
              type="ref"
              id="ref"
              name="ref"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="type">Type</label>
            <select
              onChange={(e) => setType(e.target.value)}
              value={type}
              id="propertyType"
              name="propertyType"
            >
              <option value="appartement">Appartement</option>
              <option value="maison">Maison</option>
              <option value="terrain">Terrain</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="surface">Surface</label>
            <div className="field">
              <input
                onChange={(e) => setSurface(Number(e.target.value))}
                value={surface}
                type="number"
                id="surface"
                name="surface"
                placeholder="surface"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="surface">Pièces</label>
            <div className="field">
              <input
                onChange={(e) => setPieces(Number(e.target.value))}
                value={pieces}
                type="number"
                id="pieces"
                name="pieces"
                placeholder="Nb de pieces"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="prix">Prix</label>
            <div className="field">
              <input
                onChange={(e) => setPrice(Number(e.target.value))}
                value={price}
                type="number"
                id="prix"
                name="prix"
                placeholder="prix"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="telephone">Telephone</label>
            <input
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              type="telephone"
              id="telephone"
              name="telephone"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="message">Description</label>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              id="message"
              name="message"
              required
            ></textarea>
          </div>
          <div className="field">
            <label htmlFor="caracteristique">features</label>
            <textarea
              onChange={(e) => setFeatures(e.target.value)}
              value={features}
              id="caracteristique"
              name="caracteristique"
              required
            ></textarea>
          </div>
          <div className="field">
            <label htmlFor="images">Images</label>
            <div className="field">
              <input
                type="file"
                id="images"
                name="images"
                placeholder="images"
                multiple
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="field submit">
            <button type="submit" onClick={onSubmit}>
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
