import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Search-bar.scss";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { HouseService } from "../../services/HouseService";

export interface SearchParams {
  location: string;
  rooms: number | "";
  propertyType: string;
  maxBudget: number | "";
}

interface SearchBarProps {
  houseService: HouseService;
  hasShadow?: boolean;
  onSearch: (searchParams: SearchParams) => void;
}

export function SearchBar({
  houseService,
  hasShadow = true,
  onSearch,
}: SearchBarProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [locationInput, setLocationInput] = useState("");
  const [citySuggestions, setCitySuggestions] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [rooms, setRooms] = useState<number | "">("");
  const [propertyType, setPropertyType] = useState<string>("");
  const [maxBudget, setMaxBudget] = useState<number | "">("");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSearch({
      location: locationInput,
      rooms: rooms,
      propertyType: propertyType,
      maxBudget: maxBudget,
    });
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    async function fetchUniqueCities() {
      const cities = await houseService.getCities();

      return cities;
    }

    fetchUniqueCities().then((cities) => setCities(cities));

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isInputFocused, setInputFocused] = useState(false);
  // Les autres états comme avant...

  // Gestionnaire d'événements pour définir l'état lors du focus sur l'input
  const handleFocus = () => {
    setInputFocused(true);
  };

  // Gestionnaire d'événements pour réinitialiser l'état lors de la perte de focus
  const handleBlur = () => {
    // Utiliser un délai pour permettre la sélection d'une suggestion avant de masquer la liste
    setTimeout(() => {
      setInputFocused(false);
    }, 100); // Ajustez le délai au besoin
  };

  useEffect(() => {
    if (locationInput) {
      const filteredCities = cities
        .filter((city) =>
          city.toLowerCase().includes(locationInput.toLowerCase())
        )
        .slice(0, 5);

      console.log(filteredCities, "filteredCities");

      if (filteredCities) {
        setCitySuggestions(filteredCities);
      }
    } else {
      setCitySuggestions([]);
    }
  }, [locationInput, cities]);
  return (
    <div
      className={`real-estate-search-form-wrapper ${
        hasShadow ? "hasShadow" : ""
      }`}
    >
      <form className="real-estate-search-form" onSubmit={onSubmit}>
        <div className="field type">
          <select
            id="propertyType"
            name="propertyType"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option value="">Type de bien</option>
            <option value="appartement">Appartement</option>
            <option value="maison">Maison</option>
            <option value="terrain">Terrain</option>
          </select>
        </div>
        <div className="field pieces">
          <select
            id="bedrooms"
            name="bedrooms"
            value={rooms}
            onChange={(e) => setRooms(parseInt(e.target.value) || "")} // Convertissez la chaîne en nombre ou en chaîne vide
          >
            <option value="">Pièces</option>
            <option value="1">1+</option>
            <option value="2">2+</option>
            <option value="3">3+</option>
            <option value="4">4+</option>
            <option value="5">5+</option>
          </select>
        </div>
        <div className="field search">
          <input
            type="text"
            id="location"
            name="location"
            placeholder="Localisation"
            value={locationInput}
            onFocus={handleFocus} // Gérer le focus ici
            onBlur={handleBlur} // Gérer la perte de focus ici
            onChange={(e) => setLocationInput(e.target.value)} // Mettre à jour l'état sur la saisie de l'utilisateur
            autoComplete="off" // Désactiver la complétion automatique du navigateur
          />
          {isInputFocused && (
            <ul className="city-suggestions">
              {
                citySuggestions.length > 0
                  ? citySuggestions.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setLocationInput(city);
                          setInputFocused(false);
                        }}
                      >
                        {city}
                      </li>
                    ))
                  : locationInput && <li>Pas de ville disponible</li> // Afficher ce message si aucune suggestion n'est trouvée
              }
            </ul>
          )}
        </div>
        <div className="field">
          <input
            type="number"
            id="max_budget"
            name="max_budget"
            placeholder="Prix maximum"
            value={maxBudget}
            onChange={(e) => setMaxBudget(parseInt(e.target.value) || "")} // Convertissez la chaîne en nombre ou en chaîne vide
          />
        </div>
        <div className="field button">
          <button
            className="real-estate-search-form-search-button"
            type="submit"
          >
            {windowWidth < 812 ? (
              <div className="button-content">
                <FontAwesomeIcon icon={faSearch} />
                <span className="search-button">Search</span>
              </div>
            ) : (
              <FontAwesomeIcon icon={faSearch} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
